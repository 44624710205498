<template>
  <div id="right" :class="$style.table">
    <p :class="$style.title">
      用户列表
      <BjLink :class="$style.btn" type="primary" @click="onAdd()">
        <i class="ri-equalizer-line" />
        自定义列表
      </BjLink>
    </p>
    <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
      <template #choose="item">
        <div :class="$style.choose">
          <a-checkbox :checked="selectedRowKeys.indexOf(item.id) !== -1" @change="onSelect(item.id)" />
        </div>
      </template>

      <template #user="item">
        <div :class="$style.avatar">
          <img :src="item.avatar" />
          <span class="primary"> {{ item.nickname }}</span>
        </div>
      </template>
      <template #state="item">
        <BjTag :type="item.status === 1 ? 'primary' : 'danger'">{{ statusName(item.status) }}</BjTag>
      </template>
      <template #action="item">
        <bj-link v-permission="'user.manage.list-detail'" type="primary" @click="onDetail(item)"> 详情 </bj-link>
        <a-popover :trigger="['click']" @visibleChange="visibleChange($event, item)">
          <template slot="content">
            <div :class="$style.tag">
              <BjSelect
                v-model="edit.tag_id"
                mode="multiple"
                :max-tag-count="1"
                :max-tag-text-length="3"
                key-field="id"
                placeholder="请选择用户标签"
                value-field="id"
                label-field="name"
                show-all
                :options="tagArr"
              />
              <BjButton type="primary" @click="onEdit(item)">确定</BjButton>
            </div>
          </template>
          <bj-link v-permission="'user.manage.list-tag'" type="primary"> 贴标签 </bj-link>
        </a-popover>

        <a-dropdown placement="bottomRight" :trigger="['click']">
          <bj-link v-permission="'user.manage.list-black'" type="primary">
            黑名单<i :class="$style.drop" class="ri-arrow-down-s-line right" />
          </bj-link>
          <a-menu slot="overlay" class="list-drop">
            <a-menu-item>
              <bj-link>
                禁止评论
                <a-checkbox
                  class="pull-right"
                  :checked="item.status === 2 || item.status === 3"
                  @change="onStatus(item, 2)"
                />
              </bj-link>
            </a-menu-item>
            <a-menu-item>
              <bj-link>
                禁止访问
                <a-checkbox class="pull-right" :checked="item.status === 3" @change="onStatus(item, 3)" />
              </bj-link>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </a-table>
    <div v-if="selectedRowKeys.length" :class="$style.option" :style="{ width: optionWidth }">
      <div :class="$style.optionBtn">
        <span>已选择{{ selectedRowKeys.length }}条</span>

        <span class="pull-right">
          <a-popover :trigger="['click']">
            <template slot="content">
              <div :class="$style.tag">
                <BjSelect
                  v-model="edit.tag_id"
                  mode="multiple"
                  :max-tag-count="1"
                  :max-tag-text-length="3"
                  key-field="id"
                  placeholder="请选择用户标签"
                  value-field="id"
                  label-field="name"
                  show-all
                  :options="tagArr"
                />
                <BjButton type="primary" @click="onEdits()">确定</BjButton>
              </div>
            </template>
            <bj-button class="mr-10" type="primary">贴标签</bj-button>
          </a-popover>

          <a-dropdown :trigger="['click']">
            <bj-button>
              黑名单
              <i class="ri-arrow-down-s-line right" />
            </bj-button>
            <a-menu slot="overlay" class="list-drop">
              <a-menu-item>
                <bj-link>
                  禁止评论
                  <a-checkbox :checked="edit.state > 1" class="pull-right" @change="onMoreState($event, 2)" />
                </bj-link>
              </a-menu-item>
              <a-menu-item>
                <bj-link>
                  禁止访问
                  <a-checkbox :checked="edit.state === 3" class="pull-right" @change="onMoreState($event, 3)" />
                </bj-link>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </div>
    </div>
    <bj-modal :width="800" :class="$style.modal" title="自定义列表" :visible="visible.config" @cancel="configCancel">
      <div :class="$style.box">
        <div :class="$style.left">
          <p :class="$style.title">所有字段</p>
          <div :class="$style.btns">
            <div v-for="item in config" :key="item.key" :class="$style.btn">
              <a-checkbox :checked="item.visible" @change="onChange(item)">
                {{ item.name }}
              </a-checkbox>
            </div>
          </div>
        </div>
        <div :class="$style.right">
          <p :class="$style.title">显示字段</p>
          <Draggable v-model="moveData" ghost-class="ghost" handle=".mover" tag="tbody">
            <div v-for="(item, index) in moveData" :key="item.key" :class="$style.select">
              <img :class="$style.mover" class="mover" :src="oss + '/manage/icon/default/sort.png'" alt="" />
              <div :class="$style.name">
                {{ item.name }}
              </div>
              <i :class="$style.close" class="ri-close-line" @click="onDelete(index, item.key)" />
            </div>
          </Draggable>
        </div>
      </div>
      <template #footer>
        <a-row>
          <a-col class="text-left" span="12">
            <span class="primary cursor" :class="$style.line" @click="defaultConfig()">恢复默认设置</span>
          </a-col>
          <a-col class="text-right" span="12">
            <BjButton @click="configCancel()">取消</BjButton>
            <BjButton type="primary" @click="configOk()">确定</BjButton>
          </a-col>
        </a-row>
      </template>
    </bj-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import Draggable from 'vuedraggable'

import { userListService } from '@/service'

const service = new userListService()

export default {
  name: 'Home',
  components: {
    Draggable,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    tagArr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [],
      config: [],
      visible: {
        config: false,
      },
      moveData: [],
      edit: {
        tag_id: [],
        state: 1, // 用于多选的多选框状态保存
      },
      selectedRowKeys: [],
      optionWidth: '800px',
    }
  },
  created() {
    this.getColumns()
  },
  mounted() {
    this.optionWidth = document.getElementById('right').offsetWidth + 'px'
    window.addEventListener('resize', this.dealWidth)
  },
  methods: {
    async getColumns() {
      try {
        const { data } = await service.getConfig({
          key: 'cloud_user_table_columns',
        })
        this.config = data.cloud_user_table_columns
        this.moveData = []
        this.config.map(item => {
          if (item.visible) {
            this.moveData.push(item)
          }
        })
        this.moveData = _.sortBy(this.moveData, item => item.sort)
        this.dealColumns(this.moveData)
      } catch (e) {}
    },
    dealColumns(_data) {
      this.columns = []
      this.columns.push({
        title: '',
        scopedSlots: {
          customRender: 'choose',
        },
      })
      this.columns.push({
        title: '用户',
        scopedSlots: {
          customRender: 'user',
        },
      })
      _data.map(item => {
        if (item.key === 'user_id' && item.visible) {
          this.columns.push({
            title: '用户ID',
            dataIndex: 'id',
            customRender: value => value || '-',
          })
        }
        if (item.key === 'telephone' && item.visible) {
          this.columns.push({
            title: '手机号码',
            dataIndex: 'telephone',
            customRender: value => value || '-',
          })
        }
        if (item.key === 'real_name' && item.visible) {
          this.columns.push({
            title: '真实姓名',
            dataIndex: 'real_name',
            customRender: value => value || '-',
          })
        }
        if (item.key === 'gender' && item.visible) {
          this.columns.push({
            title: '用户性别',
            dataIndex: 'gender',
            customRender: value => (!value ? '-' : value === 1 ? '男' : '女'),
          })
        }
        if (item.key === 'birthday' && item.visible) {
          this.columns.push({
            title: '用户生日',
            dataIndex: 'birthday',
            customRender: value => value || '-',
          })
        }
        if (item.key === 'city' && item.visible) {
          this.columns.push({
            title: '所在地区',
            // dataIndex: 'city',
            customRender: item => item.country + item.province + item.city || '-',
          })
        }
        if (item.key === 'source_type' && item.visible) {
          this.columns.push({
            title: '首次来源',
            dataIndex: 'source_name',
            // customRender: item => this.sourceName(item.source_type),
          })
        }
        if (item.key === 'terminal_type' && item.visible) {
          this.columns.push({
            title: '前端渠道',
            dataIndex: 'terminal_types_name',
            // customRender: value => value,
          })
        }
        if (item.key === 'created_at' && item.visible) {
          this.columns.push({
            title: '加入时间',
            dataIndex: 'created_at',
          })
        }
        if (item.key === 'last_active_time' && item.visible) {
          this.columns.push({
            title: '最后活跃时间',
            dataIndex: 'last_active_time',
          })
        }
        if (item.key === 'status' && item.visible) {
          this.columns.push({
            title: '用户状态',
            scopedSlots: {
              customRender: 'state',
            },
          })
        }
        if (item.key === 'user_integral' && item.visible) {
          this.columns.push({
            title: '积分数量',
            dataIndex: 'user_integral',
          })
        }
      })
      this.columns.push({
        title: '管理',
        scopedSlots: {
          customRender: 'action',
        },
      })
    },
    statusName(_name) {
      switch (_name) {
        case 1:
          return '正常'
        case 2:
          return '功能受限'
        case 3:
          return '禁用'
        case 4:
          return '用户已注销'
      }
    },
    async configOk() {
      try {
        await service.saveConfig({
          key: 'cloud_user_table_columns',
          value: JSON.stringify(this.moveData),
        })
        this.visible.config = false
        this.getColumns()
      } catch (e) {}
    },
    configCancel() {
      this.visible.config = false
    },
    onAdd() {
      this.visible.config = true
    },
    onChange(_item) {
      _item.visible = !_item.visible
      if (_item.visible) {
        this.moveData.push(_item)
      } else {
        this.moveData.map((item, index) => {
          if (_item.key === item.key) {
            this.moveData.splice(index, 1)
          }
        })
      }
    },
    async defaultConfig() {
      try {
        await service.saveConfig({
          key: 'cloud_user_table_columns',
          set_default: 1,
        })
        this.getColumns()
        this.visible.config = false
        this.$message.success('恢复默认成功')
      } catch (e) {}
    },
    onDelete(_index, _key) {
      this.moveData.splice(_index, 1)
      this.config.map(item => {
        if (item.key === _key) {
          item.visible = false
        }
      })
    },
    onDetail(item) {
      this.$router.push({
        name: 'userManageListDetail',
        params: {
          id: item.id,
        },
      })
    },
    visibleChange(e, item) {
      if (e) {
        this.edit.tag_id = item.tag_ids
      } else {
        this.edit.tag_id = []
      }
    },
    async onEdit(_item) {
      try {
        await service.saveTag({
          user_ids: [_item.id],
          tag_ids: this.edit.tag_id,
        })
        this.$message.success('修改成功')
        this.$emit('refresh')
      } catch (e) {}
    },
    // 批量贴标签
    async onEdits() {
      try {
        await service.saveTag({
          user_ids: this.selectedRowKeys,
          tag_ids: this.edit.tag_id,
          multi: 1,
        })
        this.$message.success('修改成功')
        this.edit.tag_id = []
        this.selectedRowKeys = []
        this.$emit('refresh')
      } catch (e) {}
    },
    // 表格选择
    onSelect(_id) {
      if (this.selectedRowKeys.indexOf(_id) !== -1) {
        this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(_id), 1)
      } else {
        this.selectedRowKeys.push(_id)
      }
    },
    // 处理状态
    async onStatus(item, _state) {
      try {
        let state = 1
        // 处理取消情况 貌似没有规律 只能这么写
        if (item.status === 1) {
          state = _state
        } else if (item.status === 2) {
          if (_state === 2) {
            state = 1
          }
          if (_state === 3) {
            state = 3
          }
        } else if (item.status === 3) {
          if (_state === 2) {
            state = 1
          }
          if (_state === 3) {
            state = 2
          }
        }
        await service.saveStatus({
          user_ids: [item.id],
          status: state,
        })
        this.$message.success('修改成功')
        this.$emit('refresh')
      } catch (e) {}
    },
    async onBlack() {
      try {
        await service.saveStatus({
          user_ids: this.selectedRowKeys,
          status: this.edit.state,
        })
        this.$message.success('修改成功')
        this.$emit('refresh')
        this.selectedRowKeys = []
        this.edit.state = 1
      } catch (e) {}
    },
    onMoreState(e, _state) {
      if (e.target.checked) {
        this.edit.state = _state
      } else {
        if (_state === 2) {
          this.edit.state = 1
        } else {
          this.edit.state = 2
        }
      }
      this.onBlack()
    },
  },
}
</script>

<style lang="less" module>
.table {
  // min-height: calc(100vh - 312px);

  .title {
    font-size: 16px;

    .btn {
      float: right;
      font-size: 14px;

      i {
        font-size: 18px;
        vertical-align: -4px;
      }
    }
  }

  .option {
    position: fixed;
    bottom: 20px;
    height: 60px;
    line-height: 60px;
    pointer-events: none;

    &-btn {
      padding: 0 20px;
      background: #fff;
      box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
      pointer-events: auto;
    }
  }
}

.modal {
  .line {
    line-height: 32px;
  }

  .box {
    display: flex;

    .left {
      width: 460px;
      height: 440px;
      margin-right: 20px;
      border: 1px solid #eee;
    }

    .right {
      flex: 1;
      height: 440px;
      overflow-x: hidden;
      overflow-y: auto;
      border: 1px solid #eee;

      .select {
        display: flex;
        align-items: center;
        width: 280px;
        height: 46px;
        padding: 0 14px 0 10px;

        .mover {
          width: 20px;
          height: 20px;
        }

        .name {
          flex: 1;
          padding-left: 16px;
          font-size: 14px;
        }

        .close {
          color: @error-color;
          font-size: 18px;
        }
      }
    }

    .title {
      height: 46px;
      margin-bottom: 0;
      padding-left: 5px;
      font-size: 14px;
      line-height: 46px;
      background-color: #fafafa;
    }

    .btns {
      display: flex;
      flex-wrap: wrap;
      padding: 20px;

      .btn {
        width: 33.3%;
        height: 35px;
      }
    }
  }
}

.tag {
  display: flex;
  align-items: center;
  width: 300px;

  :global {
    .ant-form-item {
      width: 190px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
}

.avatar {
  display: flex;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 100%;
    object-fit: cover;
  }
}

.drop {
  color: #5c5c5c;
  font-size: 18px;
  vertical-align: -4px;
}
</style>
