<template>
  <div :class="$style.home">
    <BjSearch @refresh="onSearch" @init="init">
      <BjInput
        v-model="search.search_content"
        :class="$style.input"
        v-bind="layout"
        allow-clear
        label="搜索用户"
        :placeholder="placeholder"
        @pressEnter="onSearch"
      >
        <a-select slot="addonBefore" v-model="search.search_type" style="width: 100px">
          <a-select-option :value="1"> 用户昵称 </a-select-option>
          <a-select-option :value="2"> 手机号码 </a-select-option>
          <a-select-option :value="3"> 真实姓名 </a-select-option>
          <i slot="suffixIcon" class="ri-arrow-down-s-line suffix" />
        </a-select>
      </BjInput>
      <BjSelect
        v-model="search.source_type"
        v-bind="layout"
        key-field="id"
        label="首次来源"
        inner-search
        placeholder="全部渠道"
        value-field="id"
        label-field="name"
        show-all
        :options="sourceArr"
      />
      <BjSelect
        v-model="search.terminal_type"
        v-bind="layout"
        key-field="id"
        label="前端渠道"
        inner-search
        placeholder="全部渠道"
        value-field="id"
        label-field="name"
        show-all
        :options="sourceArr"
      />
      <BjSelect
        v-model="search.tag_ids"
        v-bind="layout"
        key-field="id"
        label="用户标签"
        value-field="id"
        label-field="name"
        show-all
        :options="showtagArr"
      />
      <BjValidationItem label="加入时间" v-bind="layout">
        <a-range-picker v-model="create" />
      </BjValidationItem>
      <BjValidationItem label="最近活跃" v-bind="layout">
        <a-range-picker v-model="last" />
      </BjValidationItem>
    </BjSearch>
    <div :class="$style.table">
      <Table :data="data" :tag-arr="tagArr" :loading="loading" @refresh="getList" />
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { userListService } from '@/service'

import Table from './Table.vue'

const service = new userListService()

export default {
  name: 'Home',
  components: {
    Table,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      page: 1,
      pageSize: 20,
      total: 0,
      search: {
        search_type: 1,
        search_content: null,
        source_type: '',
        terminal_type: '',
        tag_ids: '',
        created_start: '',
        created_end: '',
        last_active_start: '',
        last_active_end: '',
      },
      create: [],
      last: [],
      tagArr: [],
      showtagArr: [],
      sourceArr: [
        {
          name: '全部渠道',
          id: '',
        },
        {
          name: '微信小程序',
          id: 'wx',
        },
        {
          name: '移动网页端',
          id: 'h5',
        },
        // {
        //   name: '百度小程序',
        //   id: 'baidu',
        // },
        // {
        //   name: '头条小程序',
        //   id: 'tt',
        // },
      ],
      data: [],
      loading: false,
    }
  },
  computed: {
    placeholder() {
      return this.search.search_type === 1
        ? '请输入用户昵称'
        : this.search.search_type === 2
        ? '请输入手机号码'
        : '请输入真实姓名'
    },
  },
  created() {
    this.getTag()
    this.getList()
  },
  methods: {
    async getTag() {
      const { data } = await service.getTagList({
        no_page: 1,
      })
      this.tagArr = data
      this.showtagArr = [
        {
          name: '全部标签',
          id: '',
        },
      ].concat(data)
    },
    async getList() {
      try {
        this.loading = true
        if (this.create.length) {
          this.search.created_start = this.create[0].format('YYYY-MM-DD')
          this.search.created_end = this.create[1].format('YYYY-MM-DD')
        } else {
          this.search.created_start = ''
          this.search.created_end = ''
        }
        if (this.last.length) {
          this.search.last_active_start = this.last[0].format('YYYY-MM-DD')
          this.search.last_active_end = this.last[1].format('YYYY-MM-DD')
        } else {
          this.search.last_active_start = ''
          this.search.last_active_end = ''
        }
        const { data } = await service.getList({
          page: this.page,
          page_size: this.pageSize,
          ...this.search,
          tag_ids: this.search.tag_ids.length ? [this.search.tag_ids] : [],
        })
        this.data = data.record
        this.total = data.total
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
    init() {
      this.search = {
        search_type: 1,
        search_content: null,
        source_type: '',
        terminal_type: '',
        tag_ids: '',
        created_start: '',
        created_end: '',
        last_active_start: '',
        last_active_end: '',
      }
      this.create = []
      this.last = []
      this.onSearch()
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.home {
  :global {
    .ant-select-arrow {
      top: 40%;
    }
  }

  .input {
    :global {
      .ant-input-group-wrapper {
        position: relative;
        top: 4px;
      }

      .ant-select-selection__rendered {
        margin-left: 15px;
        color: #5c5c5c;
      }
    }
  }

  .table {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
  }
}
</style>
